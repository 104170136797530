import React, { useState } from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import './../assets/ScannerOverlay.css'; // External CSS for cleaner structure

const ScannerOverlay = ({ setIsScanning, setBarcode, funcPos }) => {
  return (
    <div className="scanner-overlay">
      <button
        onClick={() => setIsScanning(false)}
        className="close-button"
        aria-label="Close Scanner"
      >
        Fechar
      </button>
      <BarcodeScannerComponent
        width={'500'}
        height={'500'}
        onUpdate={(err, result) => {
            console.log(err);
            console.log(result);
          if (result) {
            setBarcode(result.text);
            setIsScanning(false);
            funcPos(result.text);
          } else if (err) {
            console.error("Error scanning barcode: ", err);
          }
        }}
      />
    </div>
  );
};

export default ScannerOverlay;
